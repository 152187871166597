import React from "react";

import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material";

const StepIconCheck = styled(Check)(({ theme }) => ({
  color: " #fff",
  zIndex: 1,
  display: "block",
  margin: "auto",

  "&.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
    fontSize: 18,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

const StepIconCircle = styled("div")(({ theme }) => ({
  width: "100%",
  textAlign: "center",
}));

const StepIconRoot = styled("div")(
  ({
    theme,
    active,
    completed,
    animatedCompleted,
    animatedActive,
    disabled,
  }) => ({
    display: "flex",
    height: 24,
    width: 24,
    alignItems: "center",
    borderRadius: "50%",
    boxSizing: "border-box",
    fontSize: 10,
    zIndex: 1,
    color: disabled ? "#fff" : "#4A4A4A",
    border: "2px solid",
    borderColor: disabled
      ? theme.palette.primary.disabled
      : theme.palette.secondary.main,
    fontWeight: 700,
    backgroundColor: disabled
      ? theme.palette.primary.disabled
      : completed
      ? theme.palette.secondary.main
      : "#fff",

    animation: animatedCompleted
      ? `onChangeIcon .8s ${theme.transitions.easing.easeInOut}`
      : animatedActive
      ? `onChangeIconActive .8s ${theme.transitions.easing.easeInOut}, onChangeIcon .8s ${theme.transitions.easing.easeInOut}`
      : "none",
    animationFillMode: "forwards",
    animationDelay: animatedActive ? ".5s" : ".2s",

    "&.stepper__step-animating": {
      color: "#fff",
      backgroundColor: theme.palette.primary.disabled,
      borderColor: theme.palette.primary.disabled,
    },

    [theme.breakpoints.up("sm")]: {
      height: 36,
      width: 36,
      border: "3px solid",
      fontSize: 13,
      borderColor: disabled
        ? theme.palette.primary.disabled
        : theme.palette.secondary.main,

      "@keyframes onChangeIcon": {
        "0%": {
          transform: "scale(1)",
        },
        "25%": {
          transform: "scale(1.1)",
        },
        "55%": {
          transform: "scale(1.2)",
        },
        "75%": {
          transform: "scale(1.1)",
        },
        "100%": {
          transform: "scale(1)",
        },
      },

      "@keyframes onChangeIconActive": {
        "0%": {
          color: "#fff",
          backgroundColor: theme.palette.primary.disabled,
          borderColor: theme.palette.primary.disabled,
        },

        "100%": {
          backgroundColor: "#fff",
          borderColor: theme.palette.secondary.main,
          color: "#4A4A4A",
        },
      },
    },

    "@keyframes onChangeIcon": {
      "0%": {
        transform: "scale(1)",
      },
      "25%": {
        transform: "scale(1.1)",
      },
      "55%": {
        transform: "scale(1.15)",
      },
      "75%": {
        transform: "scale(1.1)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },

    "@keyframes onChangeIconActive": {
      "0%": {
        color: "#fff",
        backgroundColor: theme.palette.primary.disabled,
        borderColor: theme.palette.primary.disabled,
      },

      "100%": {
        backgroundColor: "#fff",
        borderColor: theme.palette.secondary.main,
        color: "#4A4A4A",
      },
    },
  })
);

export const LsbStepIcon = props => {
  const {
    active,
    completed,
    isActiveAnimated,
    isCompletedAnimated,
    stepCompleted,
  } = props;

  return (
    <StepIconRoot
      active={active && !completed}
      completed={completed}
      animatedCompleted={completed && !isCompletedAnimated}
      animatedActive={!completed && active && !isActiveAnimated}
      disabled={!stepCompleted && !active && !completed}
      className={
        !completed && active && !isActiveAnimated
          ? "stepper__step-animating"
          : "stepper__step"
      }
    >
      {completed ? (
        <StepIconCheck />
      ) : (
        <StepIconCircle>{props.icon}</StepIconCircle>
      )}
    </StepIconRoot>
  );
};
