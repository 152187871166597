import { createTheme } from "@mui/material/styles";

const secondaryColor = "#47A660";

const lightPrimary = "#E9F4E7";

const textColor = {
  primary: "#191A1A",
  secondary: "#9DA4A4",
  lighter: "#5F6161",
  helper: "#0D1538",
};
const colorMaps = {
  primaryColor: {
    100: "#0d5d4b",
    150: "#06251c",
    125: "#104b3d",
    90: "#217056",
    80: "#2c8164",
    50: "#499d81",
    40: "#8fccb5",
    30: "#c8e9d5",
    20: "#e1efe4",
    10: "#eef7f0",
    5: "#f6f9f6",
  },
  neutralColor: {
    100: "#171717",
    200: "#1a0a0a",
    90: "#363a38",
    80: "#515252",
    70: "#6b706e",
    50: "#a3a3a3",
    40: "#b5b5b5",
    30: "#cbcdcc",
    20: "#e5e6e6",
    10: "#eff0f0",
    5: "#FCFCFC",
    4: "#f7f8f8",
  },
};

const disabledColor = "#DCE0E0";
const lightColor = "#F4F6F6";

const dangerColor = "#E20D0D";
const greenMutedColor = "#E5EDE5";
const greedDarkenColor = "#58796E";

const basicTheme = createTheme({
  breakpoints: {
    keys: ["xs", "s", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      s: 375,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    secondary: {
      main: "#47A660",
      light: "#E9F4E7",
      muted: '"#9DB4A3"',
    },
    background: {
      default: "#FCFCFC",
    },

    // old begins
    primary: {
      main: colorMaps.primaryColor[100],
      lighter: colorMaps.primaryColor[90],
      disabled: disabledColor,
      light: colorMaps.primaryColor[50],
      muted: greenMutedColor,
      darken: greedDarkenColor,
    },
    greyColor: {
      light: lightColor,
    },
    neutral: {
      main: colorMaps.neutralColor[100],
      light: colorMaps.neutralColor[90],
      lighter: colorMaps.neutralColor[80],
      darken: colorMaps.neutralColor[200],
    },
    primaryColor: {
      ...colorMaps.primaryColor,
    },
    neutralColor: {
      ...colorMaps.neutralColor,
    },
    basicBackgroundColor: "#fff",
    whiteFadedColor: "#F1F4F1",
    dangerColor: dangerColor,
    tooltip: {
      background: "#EBEBF3",
      text: "#0D1538",
    },
    text: {
      primary: textColor.primary,
      secondary: textColor.secondary,
      disabled: textColor.secondary,
      hint: textColor.secondary,
      lighter: textColor.lighter,
      greenTitle: "#13614F",
    },
    divider: "#979797",
    red: "#D3423D",
    backgrounds: {
      error: "#FCE9E9",
      success: "#EBFCF0",
      warning: "#FEF9EC",
    },
    message: {
      success: {
        borderColor: "#47A660",
        backgroundColor: "#EBFCF0",
      },
      info: {
        borderColor: "#4E92F8",
        backgroundColor: "#d8e7fd",
      },
      warning: {
        borderColor: "#FBC841",
        backgroundColor: "#FEF9EC",
      },
      error: {
        borderColor: "#E12727",
        backgroundColor: "#FCE9E9",
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: ["Lato", "Arial", "sans-serif"].join(","),
        htmlFontSize: 16,
      },
    },
  },

  typography: {
    fontFamily: ["Lato", "Arial", "sans-serif"].join(","),
    fontSize: 14,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    lineHeight: "1.5rem",
    fontHeavy: ["Lato Black", "Lato", "Segoe UI", "Arial", "sans-serif"].join(
      ","
    ),
  },
  // old ends
});

const LsbTheme = createTheme(basicTheme, {
  palette: {
    primary: {
      main: colorMaps.primaryColor[100],
      lighter: colorMaps.primaryColor[90],
      disabled: disabledColor,
      light: colorMaps.primaryColor[50],
      muted: greenMutedColor,
      darken: greedDarkenColor,
    },
    greyColor: {
      light: lightColor,
    },
    neutral: {
      main: colorMaps.neutralColor[100],
      lighter: colorMaps.neutralColor[90],
      darken: colorMaps.neutralColor[200],
    },
    primaryColor: {
      100: "#0d5d4b",
      150: "#06251c",
      125: "#104b3d",
      90: "#217056",
      80: "#2c8164",
      50: "#499d81",
      40: "#8fccb5",
      30: "#c8e9d5",
      20: "#e1efe4",
      10: "#eef7f0",
      5: "#f6f9f6",
    },
    neutralColor: {
      100: "#171717",
      200: "#1a0a0a",
      90: "#363a38",
      80: "#515252",
      70: "#6b706e",
      50: "#a3a3a3",
      40: "#b5b5b5",
      30: "#cbcdcc",
      20: "#e5e6e6",
      10: "#eff0f0",
      5: "#FCFCFC",
      4: "#f7f8f8",
      3: "#F5F5F5",
    },
    basicBackgroundColor: "#fff",
    whiteFadedColor: "#F1F4F1",
    dangerColor: dangerColor,
    blueColor: "#4E92F8",
    text: {
      primary: colorMaps.neutralColor[90],
      secondary: textColor.secondary,
      disabled: textColor.secondary,
      hint: textColor.secondary,
      lighter: textColor.lighter,
      greenTitle: "#13614F",
    },
    divider: "#979797",
    red: "#D3423D",
    message: {
      success: {
        borderColor: "#47A660",
        backgroundColor: "#EBFCF0",
      },
      info: {
        borderColor: "#4E92F8",
        backgroundColor: "#d8e7fd",
      },
      warning: {
        borderColor: "#FBC841",
        backgroundColor: "#FEF9EC",
      },
      error: {
        borderColor: "#E12727",
        backgroundColor: "#FCE9E9",
      },
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingRight: "20px",
          paddingLeft: "20px",
        },

        disableGutters: {
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          lineHeight: "19px",
          color: colorMaps.primaryColor[100],

          [basicTheme.breakpoints.up("sm")]: {
            width: "328px",
          },

          "& img": {
            display: "none",
          },

          "&$focused": {
            color: textColor.primary,
          },

          "&.Mui-disabled": {
            color: colorMaps.primaryColor[100],
          },
        },
        fullWidth: {
          [basicTheme.breakpoints.up("sm")]: {
            width: "100%",
          },
        },
        input: {
          marginTop: 0,
          "&$focused": {
            color: textColor.primary,
          },
          "&::placeholder": {
            color: textColor.primary,
            fontSize: "15px",
            lineHeight: "22px",
            [basicTheme.breakpoints.up("sm")]: {
              fontSize: "16px",
              lineHeight: "20px",
            },
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colorMaps.neutralColor[100],
          fontWeight: 900,
          lineHeight: "1.5rem",
          fontSize: "16px",
          paddingBottom: basicTheme.spacing(1.5),
          position: "relative",

          "&.MuiFormLabel-withHelp": {
            paddingBottom: basicTheme.spacing(0.75),
          },

          "&.Mui-disabled": {
            color: textColor.primary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colorMaps.neutralColor[100],
          fontWeight: 900,
          lineHeight: "1.5rem",
          fontSize: "16px",
          paddingBottom: basicTheme.spacing(1),

          [basicTheme.breakpoints.down("sm")]: {
            lineHeight: "1rem",
            paddingBottom: 0,
          },

          "&.Mui-disabled": {
            color: textColor.primary,
          },
        },
        shrink: {
          transform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "2.5rem",
          minWidth: "100%",
        },

        marginDense: {
          marginTop: 0,
          marginBottom: "24px",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: "2.5rem",
          minWidth: "100%",
        },

        marginDense: {
          marginTop: 0,
          marginBottom: "24px",
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "16px",
          lineHeight: "24px",

          "&.Mui-disabled": {
            color: textColor.primary,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-disabled.Mui-checked": {
            color: secondaryColor,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 32,
          padding: "1rem 1.5rem",
          fontSize: "16px",
          lineHeight: "1.5rem",
          fontWeight: 600,
          textTransform: "none",
          whiteSpace: "nowrap",
          letterSpacing: "0.3px",
          boxShadow: "none",
          "&.MuiButton-text": {
            height: "auto",
            color: basicTheme.palette.neutral.main,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        text: {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover, &:active": {
            boxShadow: "none",
            color: "#fff",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            color: textColor.secondary,
          },
        },
        containedPrimary: {
          backgroundColor: basicTheme.palette.primary.main,

          "&:hover": {
            backgroundColor: basicTheme.palette.primaryColor[90],
          },
        },
        outlined: {
          padding: "1rem 1.5rem",
          border: "1px solid",
          color: basicTheme.palette.neutral.lighter,
          borderColor: basicTheme.palette.neutral.main,

          "&:hover": {
            backgroundColor: "transparent",
            borderColor: basicTheme.palette.neutralColor[30],
          },
        },
        iconSizeMedium: {
          "& > *:first-of-type": {
            fontSize: "0.8125rem",
          },
        },
        endIcon: {
          [basicTheme.breakpoints.up("sm")]: {
            marginTop: "2px",
            marginLeft: "1rem",
            "& [role='progressbar']": {
              marginBottom: "4px",
            },
          },
          marginTop: 0,
          marginLeft: "1rem",
        },
        startIcon: {
          marginTop: 0,
          marginRight: "1rem",
          marginLeft: 0,
          [basicTheme.breakpoints.up("sm")]: {
            marginTop: "0px",
            marginRight: "1rem",
            marginLeft: "-4px",
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.9375rem",
          lineHeight: "1.2rem",
          marginTop: "8px",
          color: "#5d5d5d",
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },

    MuiStepButton: {
      styleOverrides: {
        root: {
          verticalAlign: "bottom",
        },

        vertical: {
          padding: 0,
          margin: 0,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          [basicTheme.breakpoints.up("sm")]: {
            maxWidth: "339px",
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "label + &": {
            marginTop: 0,
          },
        },
        input: {
          "&.Mui-disabled": {
            color: basicTheme.palette.primary.main,
            WebkitTextFillColor: basicTheme.palette.primary.main,
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          "&.Mui-disabled": {
            color: basicTheme.palette.primary.main,
            WebkitTextFillColor: basicTheme.palette.primary.main,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        popper: {
          pointerEvents: "auto",
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:before": {
            height: 0,
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: "0 0 16px 0",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          "& img": {
            height: "29px",
            marginRight: "5px",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          [basicTheme.breakpoints.up("sm")]: {
            minHeight: "auto",
            height: "54px",
          },

          "& img": {
            height: "29px",
            marginRight: "5px",
          },
        },
      },
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "5px 0",
          minHeight: 0,
          [basicTheme.breakpoints.up("sm")]: {
            padding: "8px 0",
          },

          "&.Mui-expanded": {
            minHeight: "0px",
            [basicTheme.breakpoints.up("sm")]: {
              minHeight: "0px",
            },
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          marginTop: "8px",
          "& .MuiFormControlLabel-root": {
            verticalAlign: "middle",
            "& .MuiButtonBase-root": {
              position: "relative",
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          "&.Mui-checked.Mui-disabled": {
            color: secondaryColor,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.MuiSvgIcon-fontSizeSmall": {
            fontSize: "22.85px",
          },
          "&.MuiSvgIcon-fontSizeMedium": {
            fontSize: "26px",

            "&.MuiChip-deleteIcon": {
              fontSize: "16px",
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "24px",
          display: "flex",
          alignItems: "center",
          backgroundColor: basicTheme.palette.neutralColor[10],
          border: "1px solid",
          borderColor: basicTheme.palette.neutralColor[20],
          color: basicTheme.palette.neutralColor[80],
          cursor: "pointer",
          transition:
            "background-color 200ms ease-in-out, border-color 200ms ease-in-out",

          "&:hover": {
            backgroundColor: basicTheme.palette.neutralColor[20],
            borderColor: basicTheme.palette.neutralColor[30],
          },
          "&.Mui-disabled": {
            opacity: 1,
            backgroundColor: basicTheme.palette.neutralColor[20],
            borderColor: basicTheme.palette.neutralColor[30],
          },
        },
        deleteIcon: {
          fontSize: "16px",
          transition: "color 200ms ease-in-out",

          "&:hover": {
            color: "#CD332D", // not from basic palette
          },

          ".Mui-disabled &": {
            display: "none",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation24: {
          boxShadow: "1px 6px 102px 0 rgba(95,97,97,0.2)",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: { boxShadow: "none" },

        primary: {
          "&:hover": {
            backgroundColor: basicTheme.palette.primary.lighter,
          },
        },

        sizeSmall: {
          [basicTheme.breakpoints.down("sm")]: {
            width: "36px",
            height: "33px",
            fontSize: "13px",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: [
            "Lato Heavy",
            "Lato Black",
            "Lato",
            "Segoe UI",
            "Arial",
            "sans-serif",
          ].join(","),
          fontSize: "1.5rem",
          lineHeight: "2rem",
          marginTop: 0,
          textAlign: "left",
          margin: "0.67em 0",
          fontWeight: 700,
        },
      },
    },
    PrivateSwitchBase: {
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: "#9DB4A3",
        },
        indeterminate: {
          backgroundColor: lightPrimary,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          [basicTheme.breakpoints.down("sm")]: {
            overflowY: "scroll",
          },
        },

        paperScrollPaper: {
          top: "50px",
          maxHeight: "calc(100% - 128px)",
        },
      },
    },
  },
});

export default LsbTheme;
