import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { ComboboxTextfield } from "./ComboboxTextField";
import { Controller, useFormContext } from "react-hook-form";
import { ComboboxMenuItem, ComboboxMenuItemName } from "./ComboboxMenuItem";
import CheckIcon from "@mui/icons-material/Check";
import { Popper } from "@mui/material";

export default function Combobox(props) {
  const [open, setOpen] = useState(false);
  const { control, formState } = useFormContext();

  const toggleList = () => {
    setOpen(!open);
  };

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value, name } }) => {
        const valueObject =
          props.options.find(item => item.countrycode === value) ?? null;

        return (
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(e, value) => {
              onChange(value?.countrycode ?? null);
            }}
            disabled={props.disabled}
            value={valueObject}
            isOptionEqualToValue={(option, value) =>
              option.countrycode === value.countrycode
            }
            getOptionLabel={option => option.name}
            options={props?.options ?? []}
            noOptionsText='Inget namn hittats.'
            PopperComponent={({ style, ...props }) => (
              // fix default placement on the top
              <Popper
                placement='bottom'
                {...props}
                style={{ ...style, height: 0 }}
              />
            )}
            ListboxProps={{ style: { maxHeight: "30vh" } }}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;

              return (
                <ComboboxMenuItem
                  key={key}
                  {...optionProps}
                  value={option.countrycode}
                >
                  <ComboboxMenuItemName>
                    <img src={option.icon} alt='img' />
                    {option.name}
                  </ComboboxMenuItemName>

                  {optionProps["aria-selected"] && (
                    <CheckIcon fontSize='16' color='primary' />
                  )}
                </ComboboxMenuItem>
              );
            }}
            renderInput={params => (
              <ComboboxTextfield
                {...params}
                name={name}
                label={props.label}
                placeholder={value ? "" : "Ange landets namn"}
                open={open}
                helpText={props.helpText}
                toggleList={toggleList}
                errors={formState.errors}
                disabled={props.disabled}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        );
      }}
    />
  );
}
