import { styled } from "@mui/material";

export const UnderlinedLink = styled("a")(({ theme, size }) => ({
  display: "inline-block",
  fontWeight: 600,
  lineHeight: "1.5rem",
  color: theme.palette.neutral.lighter,
  transition: "color 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22)",
  cursor: "pointer",
  fontSize: size === "small" ? "0.875rem" : "1rem",
  textDecoration: "underline",
  textDecorationColor: theme.palette.primaryColor[80],
  textUnderlinePosition: "under",
  textDecorationThickness: "1.5px",

  "&:hover": {
    textDecorationColor: theme.palette.primaryColor[90],
  },

  "&:focus-visible": {
    outline: `2px solid ${theme.palette.blueColor}`,
  },

  "&:visited": {
    color: theme.palette.neutral.lighter,
  },
}));
