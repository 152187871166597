import { styled } from "@mui/material";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";

export const LsbStepLabel = styled(StepLabel)(({ theme }) => ({
  [`.${stepLabelClasses.labelContainer}.${stepLabelClasses.alternativeLabel}`]:
    {
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
      },
    },

  [`&.${stepLabelClasses.vertical}`]: {
    paddingLeft: "2px",

    [`&.${stepLabelClasses.alternativeLabel}.${stepLabelClasses.root}`]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "8px",
      padding: 0,
      whiteSpace: "nowrap",
    },
  },

  [`.${stepLabelClasses.label}.${stepLabelClasses.root}`]: {
    padding: 0,
  },

  [`.${stepLabelClasses.label}`]: {
    fontSize: ".75rem",
    fontWeight: 700,
    color: "#9DA4A4",
    lineHeight: "17px",
    marginTop: 0,

    [`&.${stepLabelClasses.iconContainer}`]: {
      position: "relative",
      zIndex: 1,
    },

    [`&.${stepLabelClasses.active}`]: {
      fontSize: ".75rem",
      fontWeight: 700,
      color: theme.palette.secondary.main,
    },

    [`&.${stepLabelClasses.active}.${stepLabelClasses.completed}`]: {
      fontSize: ".75rem",
      fontWeight: 700,
      color: theme.palette.secondary.main,
    },

    [`&.${stepLabelClasses.completed}`]: {
      fontSize: ".75rem",
      fontWeight: 700,
      color: "#9DA4A4",
    },
  },
  [`.${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]: {
    marginTop: 0,
  },
}));
