import { styled } from "@mui/material";
import Stepper, { stepperClasses } from "@mui/material/Stepper";

export const LsbStepper = styled(Stepper)(({ theme }) => ({
  [`&.${stepperClasses.root}`]: {
    padding: 0,

    ".MuiStep-root": {
      width: "100%",
    },
  },

  [`&.${stepperClasses.vertical}`]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.neutralColor[3],
    borderRadius: theme.spacing(0.5),
  },

  [`&.${stepperClasses.horizontal}`]: {
    justifyContent: "space-between",
    marginLeft: "-20px",
    marginRight: "-20px",

    [theme.breakpoints.up(375)]: {
      marginLeft: "-30px",
      marginRight: "-30px",
    },

    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(-12.5% - 15px)",
      marginRight: "calc(-12.5% - 15px)",
    },
  },

  [`&.${stepperClasses.horizontal}.five-steps`]: {
    [theme.breakpoints.up(375)]: {
      marginLeft: "-17px",
      marginRight: "-17px",
    },

    [theme.breakpoints.between(500, 600)]: {
      marginLeft: "-34px",
      marginRight: "-34px",
    },

    [theme.breakpoints.down(375)]: {
      marginLeft: "-11px",
      marginRight: "-11px",
    },

    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(-12.5% - 15px)",
      marginRight: "calc(-12.5% - 15px)",
    },
  },

  [`&.${stepperClasses.horizontal}.three-steps`]: {
    [theme.breakpoints.down(375)]: {
      marginLeft: "-42px",
      marginRight: "-42px",
    },
    [theme.breakpoints.between(375, 412)]: {
      marginLeft: "-55px",
      marginRight: "-55px",
    },
    [theme.breakpoints.between(412, 500)]: {
      marginLeft: "-65px",
      marginRight: "-65px",
    },
    [theme.breakpoints.between(500, 600)]: {
      marginLeft: "-93px",
      marginRight: "-93px",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(-22.5% - 15px)",
      marginRight: "calc(-22.5% - 15px)",
    },
  },
}));
