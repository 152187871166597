import { styled } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const LsbStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}.${stepConnectorClasses.vertical}`]:
    {
      position: "relative",
      top: 0,
      left: 0,
      right: 0,
      marginLeft: "10px",
    },

  [`&.${stepConnectorClasses.active}.${stepConnectorClasses.vertical}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
      animation: `fillingLineVertical ${theme.transitions.easing.easeInOut}`,
      animationDuration: "1.2s",
      animationFillMode: "forwards",
    },
  },

  [`&.${stepConnectorClasses.vertical} .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderLeftWidth: 3,
    transition: theme.transitions.create("border-color"),
    minHeight: "8px",
  },

  [`&.${stepConnectorClasses.completed}.${stepConnectorClasses.vertical}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
      animation: "none",
    },
  },
  [`&.${stepConnectorClasses.alternativeLabel}.${stepConnectorClasses.horizontal}`]:
    {
      top: 17,
      left: "calc(-55% + 18px)",
      right: "calc(45% + 18px)",
    },

  [`&.${stepConnectorClasses.active}.${stepConnectorClasses.horizontal}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
      animation: `fillingLine ${theme.transitions.easing.easeInOut}`,
      animationDuration: "1.2s",
      animationFillMode: "forwards",
      [theme.breakpoints.up("sm")]: {
        animationDuration: "1.5s",
      },
    },
  },
  [`&.${stepConnectorClasses.completed}.${stepConnectorClasses.horizontal}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
      animation: "none",
    },
  },
  [`&.${stepConnectorClasses.horizontal} .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    transition: theme.transitions.create("border-color"),
  },

  "@keyframes fillingLine": {
    "0%": {
      width: 0,
    },
    "100%": {
      width: "100%",
    },
  },

  "@keyframes fillingLineVertical": {
    "0%": {
      height: 0,
    },
    "100%": {
      height: "100%",
    },
  },
}));
