import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ModalContent } from "../ConsentModalContent/ModalContent";
import { TextualLinkButton } from "../TextualLinkButton/TextualLinkButton";

const ConsentDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1400,
}));

const FormErrorMessage = styled(FormHelperText)(({ theme }) => ({
  color: "#f44336",
  fontWeight: 700,
  marginTop: 0,
  whiteSpace: "nowrap",
}));

const Root = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),

  "& + div": {
    marginBottom: 0,
  },

  "&:last-of-type": {
    marginBottom: 0,
  },
}));

const CheckboxFormGroup = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  marginLeft: "-4px",

  "& p": {
    margin: 0,
    fontWeight: 700,
  },

  "& .MuiFormControl-root": {
    minWidth: "auto",
    marginBottom: 0,
  },

  "& .MuiCheckbox-root": {
    padding: "0 8px 0 0",
  },
}));

const Label = styled("span")(({ theme, disabled }) => ({
  fontWeight: 700,
  opacity: disabled ? 0.15 : 1,
}));

const ConsentCheckbox = styled(Checkbox)(({ theme, checked }) => ({
  opacity: checked ? 1 : 0.15,

  "&.Mui-disabled": {
    color: theme.palette.neutral.main,
  },
}));

export const AutogiroConsent = ({
  fieldName,
  label,
  text,
  textFieldName,
  formMethods,
  handleOnClose,
}) => {
  const { formState, control, watch } = formMethods;
  const { errors } = formState;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formMethods.setValue(fieldName, true, {
      shouldValidate: true,
      shouldDirty: true,
    });

    formMethods.setValue(textFieldName, text, {
      shouldValidate: true,
      shouldDirty: true,
    });
    handleOnClose(true);
    setOpen(false);
  };

  const consentAgreed = watch(fieldName, false);

  return (
    <Root>
      <FormProvider {...formMethods}>
        <Controller
          control={control}
          name={textFieldName}
          defaultValue=''
          render={() => {
            return null;
          }}
        />
        <CheckboxFormGroup>
          <Controller
            control={control}
            name={fieldName}
            defaultValue={false}
            render={({ field: { name, value, onChange } }) => (
              <FormControl>
                <ConsentCheckbox
                  disableRipple
                  color='secondary'
                  checked={value}
                  id={fieldName}
                  name={name}
                  onChange={e => {
                    onChange(e);
                  }}
                  required
                  disabled
                />
              </FormControl>
            )}
          />

          <FormLabel htmlFor={fieldName} sx={{ paddingBottom: 0 }}>
            <div>
              <Label disabled={!consentAgreed}>{label} &nbsp;</Label>
              <TextualLinkButton
                type='button'
                onClick={handleClickOpen}
                sx={{ fontWeight: 700, padding: 0 }}
              >
                Läs och godkänn
              </TextualLinkButton>
            </div>
            <ErrorMessage
              errors={errors}
              name={fieldName}
              render={({ message }) => {
                return <FormErrorMessage>{message}</FormErrorMessage>;
              }}
            />
          </FormLabel>
        </CheckboxFormGroup>

        <ConsentDialog
          open={open}
          onClose={(event, reason) => {
            if (reason === "backdropClick" || reason === "escapeKeyDown") {
              return;
            }
          }}
          scroll={"paper"}
          disableEscapeKeyDown={true}
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >
          <DialogTitle id='scroll-dialog-title'>
            Medgivande till betalning via Autogiro
          </DialogTitle>
          <DialogContent dividers id='scroll-dialog-description' tabIndex={-1}>
            <ModalContent terms={text} />
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                color='primary'
                variant='outlined'
              >
                Avbryt
              </Button>

              <Button onClick={handleClose} color='primary' variant='contained'>
                Godkänn
              </Button>
            </DialogActions>
          </DialogContent>
        </ConsentDialog>
      </FormProvider>
    </Root>
  );
};
